:root{
    --blue: #3880ff;//#203952;
    --red: #FF6666;
    --yellow: #C9B235;
    --yellowRGB: 201, 178, 53;
    --green: #93AE75;
    .dark{
        --text: #fff;
        --textRGB: 255, 255, 255;
        --background1: #000;
        --background2: #1e1e1e;
        --background25: #2b2b2b;
        --background3: #353535;
        --ion-text-color: var(--text);
    }
    .light{
        --text: #203952;
        --textRGB: 32, 57, 82;
        --background1: #fff;
        --background2: #ECECEC;
        --background3: #fff;
        --ion-text-color: var(--text);
    }
}
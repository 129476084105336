/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "./theme/color.scss";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

// body.dark{
    
// }
body.light{
    background: #fff;
}

.card{
    padding: 16px; border-radius: 10px; width: calc(100% - 14px); margin: auto;  background: var(--background2); color: var(--text);
    h2{font-size: 16px; margin: 0; margin-top: 10px;}
    ion-item{
        color: var(--text);
    }
}

#buttons{
    .next-bttn{
        display: flex; justify-content: space-between; align-items: center; padding: 15px;  border-radius: 10px; margin: 15px 0; background: var(--background3);
        color: var(--text);
        .left{
            display: flex; align-items: center;
            ion-icon{
                margin-right: 15px; min-width: 16px;
            }
            p{
                font-size: 12px; margin-top: 3px;
            }
        }
    }
}


.customMessage{
    --background: var(--background2) !important;
    color: var(--text);
    .alert-input{
        color: var(--text); background: var(--background3); border: none; padding: 10px 5px; border-radius: 5px;
    }
    h2{
      color: var(--text) !important;
    }
    .alert-message{
      color: var(--text);
    }
    button{
      color: var(--blue); background: var(--background2) !important;
    }

}



ion-tab-bar{
    --background: var(--background2);
}

ion-header{
    -webkit-app-region: drag;
    ion-toolbar{
        --background: var(--blue); 
        --color: #fff;
        --border-width: 0 !important;
        ion-back-button{
            color: #fff;
        }
    }
}

ion-content{
    --background: var(--background1)
}

ion-footer{
    ion-toolbar{
        --background: var(--background1)
    }
}

.web-max{
    max-width: 1200px; margin: auto;
}

ion-refresher-content.md{
    .refresher-refreshing-icon{background: var(--background1); border: none;}
    .refresher-pulling-icon{background: var(--background1); border: none;}
}

ion-segment-button{
    --color-checked: #1e4790;
    --indicator-color: #e5eefe;
}

ion-picker-internal{
    --background: var(--background2)
}


ion-input {
    //border: 1px solid #afafaf;
    --background: var(--background3) !important;
    --color: var(--text) !important;
    border-radius: 10px;
    --padding-bottom: 13px !important;
    --padding-top: 13px !important;
    --padding-start: 8px !important; 
    margin-top: 10px;
    // background: #ececec !important;
    margin-bottom: 10px;
}

ion-select-popover{
    background: red;
    ion-list{
        background: var(--background2) !important;
        ion-item{
            --background: none
        }
    }
}

input[type="date"], input[type="time"]{ 
    padding: 7px 20px;
    min-width: 100px;
    border-radius: 8px; 
    cursor: text;
    border: solid 1px var(--border);
    background: var(--background3);
    color: var(--text);
}

ion-button{
    --color: #fff !important;
}

ion-back-button {
    display: block;
 }

ion-toggle{
    --track-background: rgba(var(--textRGB), 0.1);  --handle-height: 25px; --handle-width: 22px;
    &::part(track){
        height: 25px; width: 45px;
    }
}

*{
    box-sizing: border-box;
}

:fullscreen .no-fullscreen{
    display: none;
}


//page loading directive
.floating-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg{
        height: 25px; width: 25px; opacity: 0.5;
        animation: rotate 0.5s linear infinite;
        path{
            fill: var(--text)
        }
        @keyframes rotate {
            from {transform: rotate(0deg);}
            to {transform: rotate(359deg);}
        }
    }
  }


